import React, { useEffect, useState } from 'react';

const FacebookLogin = () => {
  const [sessionInfo, setSessionInfo] = useState('');
  const [sdkResponse, setSdkResponse] = useState('');

  useEffect(() => {
    // Initialize Facebook SDK
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: '493407539733380',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v21.0'
      });
    };

    // Load Facebook SDK
    const loadFacebookSDK = () => {
      const script = document.createElement('script');
      script.src = 'https://connect.facebook.net/en_US/sdk.js';
      script.async = true;
      script.defer = true;
      script.crossOrigin = 'anonymous';
      document.body.appendChild(script);
    };

    loadFacebookSDK();

    // Add message event listener
    const handleMessage = (event) => {
      if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") {
        return;
      }

      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            const { phone_number_id, waba_id } = data.data;
            console.log("Phone number ID ", phone_number_id, " WhatsApp business account ID ", waba_id);
          } else if (data.event === 'CANCEL') {
            const { current_step } = data.data;
            console.warn("Cancel at ", current_step);
          } else if (data.event === 'ERROR') {
            const { error_message } = data.data;
            console.error("error ", error_message);
          }
        }
        setSessionInfo(JSON.stringify(data, null, 2));
      } catch {
        console.log('Non JSON Responses', event.data);
      }
    };

    window.addEventListener('message', handleMessage);

    // Cleanup
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      const code = response.authResponse.code;
      // The returned code must be transmitted to your backend first and then
      // perform a server-to-server call from there to our servers for an access token.
    }
    setSdkResponse(JSON.stringify(response, null, 2));
  };

  const launchWhatsAppSignup = () => {
    // Launch Facebook login
    window.FB.login(fbLoginCallback, {
      config_id: '1744455813003229', // configuration ID goes here
      response_type: 'code', // must be set to 'code' for System User access token
      override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
      extras: {
        setup: {},
        featureType: '',
        sessionInfoVersion: '2',
      }
    });
  };

  return (
    <div>
      <div id="fb-root"></div>
      <button
        onClick={launchWhatsAppSignup}
        className="bg-[#1877f2] rounded px-6 py-2 text-white font-bold cursor-pointer"
        style={{ fontFamily: 'Helvetica, Arial, sans-serif' }}
      >
        Login with Facebook
      </button>
      
      <p>Session info response:</p>
      <pre id="session-info-response">
        {sessionInfo}
      </pre>
      
      <br />
      
      <p>SDK response:</p>
      <pre id="sdk-response">
        {sdkResponse}
      </pre>
    </div>
  );
};

export default FacebookLogin;