import React from 'react';
import './App.css';
import Header from './components/header';
import HeroSection from './components/hero_section';
import Offerings from './components/offerings';
import Spin from './components/take_them_for_spin';
import How_it_works from './components/how_it_works';
import Smart_secret from './components/smart_secret';
import CustomerSupportComponent from './components/customer_queries';

import LogOffComponent from './components/logOff';
import Footer from './components/footer';
import { Route, Routes } from 'react-router-dom';
import CancellationAndRefundPolicy from './pages/CancellationAndRefundPolicy'
import PrivacyPolicy from './pages/PrivacyPolicy';
import ShippingPolicy from './pages/ShippingPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import FacebookLogin from './pages/login';
const App = () => {
  return (
    <div>
      <Header />
      <Routes>
        {/* Home Page */}
        <Route
          path="/"
          element={
            <>
              <HeroSection />
              <Offerings />
              <Spin />
              <How_it_works />
              <Smart_secret />
              <CustomerSupportComponent />
              {/* <PricingComponent /> */}
              <LogOffComponent />
            </>
          }
        />
        {/* Another Page */}
       <Route path="/cancellation&refund-policy" element={<CancellationAndRefundPolicy />} />
       <Route path="/terms&conditions" element={<TermsAndConditions />} />
       <Route path="/privacy-policy" element={<PrivacyPolicy />} />
       <Route path="/shipping-policy" element={<ShippingPolicy />} />
       <Route path="/login" element={<FacebookLogin/>}/>
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
